import classNames from 'classnames/bind';
import React from 'react';
import type { Settings } from 'react-slick';
import SlickSlider from 'react-slick';
import 'slick-carousel/slick/slick.css';

import type { ITheme } from '@globalTypes';

import styles from './Slider.module.scss';
import AngleLeft from '@icons/angleLeft.svg';
import AngleRight from '@icons/angleRight.svg';

interface IProps {
  variant?: 'primary' | 'secondary';
  settings: Settings;
  theme?: ITheme;
  dotLabels?: string[];
  withShadow?: boolean;
  children: JSX.Element[];
}

const cx = classNames.bind(styles);

export const Slider: React.FC<IProps> = ({
  variant = 'primary',
  settings,
  dotLabels,
  theme = 'light',
  withShadow,
  children,
}) => {
  const sliderRef = React.useRef<any>(null);
  const [activeIndex, setActiveIndex] = React.useState(0);
  const { arrows, dots, ...otherSettings } = settings;

  React.useEffect(() => {
    if ('autoplay' in otherSettings) {
      if (otherSettings.autoplay) {
        sliderRef?.current.slickPlay();
      } else {
        sliderRef?.current.slickPause();
      }
    }
  }, [otherSettings?.autoplay]);

  return (
    <div className={cx('wrapper', variant, { withShadow })}>
      {dots && (
        <ul className={cx('list', theme)}>
          {React.Children.map(children, (_, index) => (
            <li className={cx('item', { isActive: index === activeIndex })}>
              {dotLabels?.[index] && <span className={styles.label}>{dotLabels[index]}</span>}
              <button
                aria-label={dotLabels?.[index] ?? `go to ${index + 1}. slide`}
                className={styles.dotWrapper}
                type="button"
                onClick={() => {
                  sliderRef?.current.slickGoTo(index);
                  setActiveIndex(index);
                }}
              >
                <span className={styles.dot}></span>
              </button>
            </li>
          ))}
        </ul>
      )}
      <div className={styles.sliderWrapper}>
        {arrows && (
          <>
            <button
              aria-label="prev slide"
              className={cx('arrow', 'prev')}
              disabled={!otherSettings?.infinite && activeIndex === 0}
              type="button"
              onClick={() => {
                sliderRef?.current.slickPrev();
              }}
            >
              <AngleLeft className={styles.icon} />
            </button>
            <button
              aria-label="next slide"
              className={cx('arrow', 'next')}
              disabled={!otherSettings?.infinite && activeIndex === children.length - 1}
              type="button"
              onClick={() => {
                sliderRef?.current.slickNext();
              }}
            >
              <AngleRight className={styles.icon} />
            </button>
          </>
        )}
        <div className={cx('slider', { withArrows: arrows })}>
          <SlickSlider
            ref={sliderRef}
            beforeChange={(_, nextSlide) => setActiveIndex(nextSlide)}
            {...otherSettings}
            arrows={false}
            dots={false}
          >
            {children}
          </SlickSlider>
        </div>
      </div>
    </div>
  );
};
