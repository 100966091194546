import classNames from 'classnames/bind';
import React from 'react';

import {
  reviewStarz,
  reviewBanijay,
  reviewEsl,
  reviewRakuten,
  reviewSport1,
  reviewUdemy,
} from './reviewsData';
import { Author } from '@components/Author/Author';
import type { IImage } from '@components/Image/Image';
import { Logo } from '@components/Logo/Logo';
import { Paragraph } from '@components/Paragraph/Paragraph';
import { Slider } from '@components/Slider/Slider';

import styles from './ReviewsCarousel.module.scss';

export type IReview = {
  logo: string | { src: string; width: number; height: number };
  text: (string | JSX.Element)[];
  author: {
    name: string;
    role: string;
    company: string;
    photo?: IImage;
  };
};

interface IProps {
  data?: IReview[];
}

const cx = classNames.bind(styles);

export const ReviewsCarousel: React.FC<IProps> = ({
  data = [reviewStarz, reviewRakuten, reviewUdemy, reviewEsl, reviewBanijay, reviewSport1],
}) => {
  const slides = React.useMemo(
    () =>
      data.map(({ logo, text, author }) => (
        <React.Fragment key={`${author.name}-${author.role}`}>
          <Logo
            className="mx-auto mb-5"
            height={typeof logo === 'string' ? 30 : logo.height}
            name={author.company}
            src={typeof logo === 'string' ? logo : logo.src}
            width={typeof logo === 'string' ? 100 : logo.width}
          />
          <blockquote>
            <Paragraph className={cx('paragraph', 'mb-5')} size="lg">
              <q>
                {text.map((item, index) => (
                  <React.Fragment key={typeof item === 'string' ? item : index}>
                    {item}
                    {index !== text.length - 1 && (
                      <>
                        <br />
                        <br />
                      </>
                    )}
                  </React.Fragment>
                ))}
              </q>
            </Paragraph>
            <Author
              className={
                author.photo ? 'justify-content-center' : 'justify-content-center text-center'
              }
              size="md"
              theme="round"
              {...author}
            />
          </blockquote>
        </React.Fragment>
      )),
    [data],
  );

  return (
    <div className="mw-10 mx-auto">
      <Slider
        settings={{
          arrows: true,
          dots: true,
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: '0px',
          variableWidth: true,
        }}
        variant="secondary"
      >
        {slides}
      </Slider>
    </div>
  );
};
