import classNames from 'classnames/bind';
import React from 'react';

import styles from './Icon.module.scss';

const cx = classNames.bind(styles);

type IColor = 'gray800' | 'blue200' | 'green400' | 'yellow200' | 'currentColor';

export interface IProps {
  size: number;
  opacity?: 50 | 70 | 100;
  color?: IColor;
  className?: string;
  children: JSX.Element;
}

export const Icon: React.FC<IProps> = ({
  size,
  opacity = 100,
  color = 'currentColor',
  className,
  children,
}) => (
  <span
    className={cx('icon', className, `opacity-${opacity}`, `color-${color}`)}
    style={{ height: size, width: size }}
  >
    {React.cloneElement(children, { width: size, height: size })}
  </span>
);
